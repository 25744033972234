import React, {memo, Suspense, useEffect} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Home, MenuAppBar, Setting} from './components/splittings';
import {getTasksData} from './actions/user';
import {useGlobal} from 'reactn';
import {TaskEventsProvider} from './context/TasksEventsContext';
import LinearProgress from '@material-ui/core/LinearProgress';

function App() {

    const [user] = useGlobal('user');

    useEffect(() => {
        if (user.user_id) {
            getTasksData();
        }
        // eslint-disable-next-line
    }, [user]);

    return (
        <TaskEventsProvider>
            <Suspense fallback={<SuspenseLoader/>}>
                <MenuAppBar/>
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path="/setting" component={Setting}/>
                    <Route component={NotFound}/>
                </Switch>
                {/*<Footer/>*/}
            </Suspense>
            <ToastContainer/>
        </TaskEventsProvider>
    );
}

function NotFound() {
    return (
        <div>
            NOT FOUND :((
        </div>
    );
}

const SuspenseLoader = memo(() => {
    return (<LinearProgress/>);
});

export default withRouter(App);
