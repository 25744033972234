import React, {createContext, useContext, useEffect, useState} from 'react';
import {setGlobal, useGlobal} from 'reactn';
import {getGeniamProjects} from './common/getProjects';
import {swapProjectByOrder} from "../actions/swapProjectByOrder";
import {useTaskUser} from "./TaskUserContext";
import _ from "lodash";

export const TaskProjectsContext = createContext({});

export const useProjectsContext = () => {
    return useContext(TaskProjectsContext);
};

export const TaskProjectsProvider = ({children}) => {
    const value = useProjects();
    return (
        <TaskProjectsContext.Provider value={value}>
            {children}
        </TaskProjectsContext.Provider>
    );
};

const useProjects = () => {
    const [user] = useGlobal('user');
    const [projects, setProjects] = useState([]);
    const {taskUser} = useTaskUser();

    useEffect(() => {
        if (!user?.user_id)
            return;

        const sub = getGeniamProjects(user.user_id, (err, snap) => {
            if (err)
                return;

            let data = snap.docs.map(doc => ({...doc.data(), id: doc.id}));
            let projectList = [];
            data.forEach(pro => {
                if (!pro.is_sync) {
                    // projectDatas.push({...pro, is_enabled: true})
                    let project = pro;
                    let proCategoties = pro.categories;
                    let proPriorities = pro.priorities;

                    let newCategories = [];
                    proCategoties.forEach(pc => {
                        if (!pc.isDeleted || pc.isDeleted !== true)
                            newCategories.push({...pc, isDeteled: false});
                    });
                    let newPriorities = [];
                    proPriorities.forEach(pr => {
                        if (!pr.isDeleted || pr.isDeleted !== true)
                            newPriorities.push({...pr, isDeteled: false});
                    });

                    project.categories = _.orderBy(newCategories, ['index'],
                        ['asc']);
                    project.priorities = _.orderBy(newPriorities, ['index'],
                        ['asc']);

                    projectList.push({...project, is_enabled: true});
                }
            });
            const {googleList = []} = taskUser;
            if (googleList && googleList.length) {
                projectList = swapProjectByOrder([...projectList], googleList)
            }
            setProjects([...projectList]);
            setGlobal({
                projects: [...projectList]
            })
        });

        return () => {
            if (sub)
                sub();
        };
        // eslint-disable-next-line
    }, [user?.user_id, taskUser]);

    return {
        projects,
        setProjects
    };
};
