import React, {createContext, useContext, useEffect} from 'react';
import {useGlobal} from 'reactn';
import {db} from '../firebaseConfig';
import {calendar} from '../actions/user';
import {useProjectsContext} from "./TaskProjectsContext";

export const TasksEventsContext = createContext({});

export const useTaskEvents = () => {
    return useContext(TasksEventsContext);
};

export const TaskEventsProvider = ({children}) => {
    const value = useEvents();
    return (
        <TasksEventsContext.Provider value={value}>
            {children}
        </TasksEventsContext.Provider>
    );
};

const useEvents = () => {
    const [user] = useGlobal('user');
    const {projects} = useProjectsContext();
    const [events, setEvents] = useGlobal('events');

    useEffect(() => {
        getEvents();
        // eslint-disable-next-line
    }, [projects]);

    const getEvents = async () => {
        if (!projects.length)
            return;
        let eventsData = [];
        await Promise.all(projects.map(async (pro) => {
            if (pro.is_enabled) {
                let eventDocRef = db.collection(`${calendar}/${user.user_id}/projects/${pro.uuid}/events`);
                let snapshots = await eventDocRef
                    .where('isTasks', '==', true)
                    .where('isDeleted', '==', false)
                    .get();

                if (!snapshots.size)
                    return null;
                snapshots.docs.forEach(snap => {
                    eventsData.push({...snap.data(), id: snap.id, uuid: snap.id})
                })
                return pro
            }
        }))
        setEvents(eventsData);
    }

    const refresh = () => {
        setEvents([...events]);
    };

    return {
        refresh,
        getEvents
    };
};
