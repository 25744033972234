import _ from 'lodash';

export const swapProjectByOrder = (sources = [], orders = []) => {
    if (!orders.length)
        return [...sources]
    let projects = [...sources]
    let nextProjects = []
    orders.forEach(id => {
        let idx = _.findIndex(projects, {id})
        if (idx !== -1) {
            nextProjects.push(projects[idx])
            projects.splice(idx, 1)
        }
    })
    return _.concat(nextProjects, projects)

};
