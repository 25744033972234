import moment from "moment";
import uuidv4 from "uuid/v4"

export const gerenateCategories = (projectId) => {
    const timeUtc = moment().utc().format()
    const uid = `cat_${uuidv4()}`
    return [
        {
            name: 'Default',
            ja_name: 'Default',
            description: 'Default ',
            index: 0,
            project_uuid: projectId,
            uuid: uid,
            created_at: timeUtc,
            updated_at: timeUtc,
            identities: []
        }
    ]
}
export const gerenatePriorities = projectId => {
    const timeUtc = moment().utc().format()
    return [
        {
            slots: 6,
            index: 0,
            name: `Priority 1`,
            ja_name: `Priority 1`,
            project_uuid: projectId,
            uuid: `pri_${uuidv4()}`,
            created_at: timeUtc,
            updated_at: timeUtc
        },
        {
            slots: 6,
            index: 1,
            name: `Priority 2`,
            ja_name: `Priority 2`,
            project_uuid: projectId,
            uuid: `pri_${uuidv4()}`,
            created_at: timeUtc,
            updated_at: timeUtc
        },
        {
            slots: 6,
            index: 2,
            name: `Priority 3`,
            ja_name: `Priority 3`,
            project_uuid: projectId,
            uuid: `pri_${uuidv4()}`,
            created_at: timeUtc,
            updated_at: timeUtc
        },
        {
            slots: 6,
            index: 3,
            name: `Suggest`,
            ja_name: `Suggest`,
            project_uuid: projectId,
            uuid: `pri_${uuidv4()}`,
            created_at: timeUtc,
            updated_at: timeUtc
        }
    ]
}
