import {db} from '../../firebaseConfig';
import {calendar} from '../../actions/user';

export const getGeniamProjects = (userId, callback) => {
    return db.collection(calendar).doc(userId).collection('projects').where('is_deleted', '==', false).onSnapshot(querySnapshot => {
            if (callback) {
                callback(null, querySnapshot);
            }
        },
        error => {
            if (callback) {
                callback(error, null);
            }
        },
    );

};
