import {getGlobal, setGlobal} from 'reactn';
import axios from 'axios';
import Cookies from 'js-cookie';
import Token, {GENIAM_ACCESS_TOKEN_KEY, GENIAM_REFRESH_TOKEN_KEY, GENIAM_USER_INFO_KEY,} from '../config/localTokens';
import {LOGIN, USER_INFO} from '../config/apiUrl';
import {auth, db} from '../firebaseConfig';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import {toast} from 'react-toastify';
import {gerenateCategories, gerenatePriorities} from '../common/constant';

export let calendar = process.env.REACT_APP_ENV === 'production' ?
    'calendar' :
    'calendar-stg';
export let identities = process.env.REACT_APP_ENV === 'production' ?
    'identities' :
    'identities-stg';
export let IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const getUserData = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
        const res = await refreshToken();
        if (!res) return null;
    }

    try {
        const {data} = await axios.get(USER_INFO);
        setGlobal({user: data});
        localStorage.setItem('user', JSON.stringify(data));
        return true;
    } catch (e) {
        console.log(e);
        return null;
    }

};

export const getTasksData = async () => {
    const {user} = getGlobal();
    const userRef = db.doc(`${calendar}/${user.user_id}`);
    try {
        const userProjects = await userRef.get();
        if (!userProjects.exists) {
            await initTask();
        }
        if (!userProjects.data().identitySettings) {
            await userRef.set({identitySettings, updated_at: moment().format()}, {merge: true})
        }
        // // create standard projeact by new user

    } catch (e) {
        console.log(e.toString());
    }
};

// export const getIdentities = async () => {
//     console.log(identities);
//     const {user} = getGlobal();
//     const IdeRef = db.collection(identities).doc(user.user_id);
//     try {
//         const userIdentities = await IdeRef.get();
//
//         // console.log(userIdentities.data());
//         if (userIdentities.exists) {
//             let userIdes = userIdentities.data().identities;
//             setGlobal({
//                 identities: [...userIdes],
//             });
//         }
//     } catch (e) {
//         console.log(e.toString());
//     }
// };

export const refreshToken = async () => {
    const {geniam_refreshToken} = Token();
    if (!geniam_refreshToken) {
        window.location.assign(
            LOGIN + '/login?redirect_url=' + window.location.href);
        return null;
    }
    try {
        const res = await axios.post('/refresh',
            {refreshToken: geniam_refreshToken});
        const data = res.data;
        localStorage.setItem(GENIAM_ACCESS_TOKEN_KEY, data.accessToken);
        return data;
    } catch (e) {
        localStorage.removeItem(GENIAM_ACCESS_TOKEN_KEY);
        Cookies.remove(GENIAM_REFRESH_TOKEN_KEY);
        localStorage.removeItem(GENIAM_USER_INFO_KEY);
        window.location.assign(
            LOGIN + '/login?redirect_url=' + window.location.href);
        return null;
    }
};

const initTask = async () => {
    try {
        const {user} = getGlobal();
        const batch = db.batch();
        const userRef = db.collection(calendar).doc(user.user_id);
        batch.set(userRef, {
            focusText: [],
            tasks: tasksDefault,
            identitySettings,
            times: timeWork,
            uuid: user.user_id,
        });
        arrProject.forEach(p => {
            const uid = 'pro_' + uuidv4();
            const projectRef = db.collection(calendar).doc(user.user_id).collection('projects').doc(uid);
            const categories = gerenateCategories(uid);
            const priorities = gerenatePriorities(uid);
            batch.set(projectRef, {
                ...p,
                uuid: uid,
                author: user.email,
                categories,
                priorities,
                is_deleted: false,
            });
        });

        await batch.commit();

    } catch (e) {
        console.log(e);
        toast.error(e.toString());
    } finally {
        window.location.reload();
    }
};
const timeWork = {
    minTime: {
        value: 7,
        label: '07:00:00',
    },
    maxTime: {
        value: 24,
        label: '24:00:00',
    },
};
const tasksDefault = {
    data: [
        {
            icon: 'AccountCircle',
            index: 0,
            is_active: 1,
            is_default: 0,
            name: 'AccountCircle',
        },
        {
            icon: 'AssignmentTurnedIn',
            index: 1,
            is_active: 1,
            is_default: 0,
            name: 'AssignmentTurnedIn',
        },
        {
            icon: 'Group',
            index: 2,
            is_active: 1,
            is_default: 1,
            name: 'Group',
        },
        {
            icon: 'AssignmentInd',
            index: 3,
            is_active: 1,
            is_default: 0,
            name: 'AssignmentInd',
        },
    ],
    default: 1,
};
const arrProject = [
    {
        color: '#3A3A3A',
        ja_name: 'Calendar 1',
        name: 'Calendar 1',
        code: 'Ca',
        created_at: moment().utc().format(),
        updated_at: moment().utc().format(),
    }
];
const identitySettings = [
    {color: "#7986CB", id: 1, isArchive: false, isDeleted: false, name: ""},
    {color: "#33B679", id: 2, isArchive: false, isDeleted: false, name: ""},
    {color: "#8E24AA", id: 3, isArchive: false, isDeleted: false, name: ""},
    {color: "#E67C73", id: 4, isArchive: false, isDeleted: false, name: ""},
    {color: "#F6BF26", id: 5, isArchive: false, isDeleted: false, name: ""},
    {color: "#F4511E", id: 6, isArchive: false, isDeleted: false, name: ""},
    {color: "#039BE5", id: 7, isArchive: false, isDeleted: false, name: ""},
    {color: "#616161", id: 8, isArchive: false, isDeleted: false, name: ""},
    {color: "#3F51B5", id: 9, isArchive: false, isDeleted: false, name: ""},
    {color: "#0B8043", id: 10, isArchive: false, isDeleted: false, name: ""},
    {color: "#D50000", id: 11, isArchive: false, isDeleted: false, name: ""}
];

export const signInFirebase = async () => {
    try {
        const data = await refreshToken();
        const res = await auth.signInWithCustomToken(data.firebaseToken);
        console.log(res);
    } catch (e) {
        console.log(e);
    }
};
