import {addCallback, setGlobal} from 'reactn'
import {isNodeEnvDevelopment} from "./config/env";

if (isNodeEnvDevelopment) addCallback(global => {
    // console.log(global)
})

setGlobal({
    // DATA
    events: [],
    projects: [],
    user: {},
    identities: [],
    taskUser: {},
    newUser: false,

    // Popup task
    newEvent: {},
    editEvent: {},
    popup: {
        newEventPopup: false,
        editEventPopup: false,
        top: 0,
        left: 0,
    },
    calendarID: {
        id: 0,
        name: ''
    },

    // Setting
    btnProjectSetting: [],
    btnCategorySetting: [],
    boards:[],
    lists:[],
    cards:[],
    data:{lanes:[]},
    boardSelect:'',
    cardEdit:{
        open:false,
        cardId:'',
    },
    deleteCard:{
        open: false,
        CardId: ''
    },
    members: [],
    eventsPro: [],
    nodeInfor: [],
    treeData: [],
    treeDatas: [],
    eventClick: {},
    openCheckExpDateDialog: false,
    servicesList: [],
    identitiesColor: [
        "#7986CB",
        "#33B679",
        "#8E24AA",
        "#E67C73",
        "#F6BF26",
        "#F4511E",
        "#039BE5",
        "#616161",
        "#3F51B5",
        "#0B8043",
        "#D50000"
    ],
})
